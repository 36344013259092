import React from "react"
import { string, array, object } from "fast-web-kit"
import { ApplicationContext } from "../../context"
import { socketURL } from "../../helpers"
import QRCodeComponent from "./qr"

const CardPreview = React.memo(() => {

    const { application } = React.useContext(ApplicationContext)

    return (
        <div className="preview">
            <div className="preview-header">
                {
                    application.state.logo
                        ?
                        <div className="preview-logo">
                            <img src={object.isValid(application.state.logo) ? URL.createObjectURL(application.state.logo) : `${socketURL}/uploads/${application.state.folderName}/${application.state.logo}`} alt="" />
                        </div>
                        : null
                }
                <div className="preview-sub-header">
                    <div className="left-section">
                        <div className="name">{string.toTitleCase(application.state.firstName.trim())} {string.toTitleCase(application.state.lastName.trim())}</div>
                        <div className="title">{string.toTitleCase(application.state.title.trim())}</div>
                    </div>
                    {
                        application.state.image
                            ?
                            <div className="right-section">
                                <img src={object.isValid(application.state.image) ? URL.createObjectURL(application.state.image) : `${socketURL}/uploads/${application.state.folderName}/${application.state.image}`} alt=""
                                    style={{
                                         borderWidth: 5,
                                         borderStyle: "solid",
                                         borderColor: application.state.color,
                                         borderRadius: "100%"
                                        }}
                                />
                            </div>
                            : null
                    }
                </div>
            </div>
            {
                (string.isNotEmpty(application.state.phoneNumber) || string.isNotEmpty(application.state.instagram) || string.isNotEmpty(application.state.whatsapp) || string.isNotEmpty(application.state.email) || string.isNotEmpty(application.state.facebook)) && string.isNotEmpty(application.state.color)
                    ?
                    <div className="contacts" style={{ backgroundColor: application.state.color }}>
                        <div className="title">contacts</div>
                        <div className="right-section">
                            {
                                string.isNotEmpty(application.state.phoneNumber)
                                    ?
                                    <a href={`tel:+${application.state.phoneNumber}`} rel="noreferrer">
                                        <img src='/call.png' alt={""} />
                                    </a>
                                    : null
                            }
                            {
                                string.isNotEmpty(application.state.whatsapp)
                                    ?
                                    <a href={`https://wa.me/+${application.state.whatsapp}`} target="blank" rel="noreferrer">
                                        <img src='/whatsapp.png' alt={""} />
                                    </a>
                                    : null
                            }
                            {
                                string.isNotEmpty(application.state.instagram)
                                    ?
                                    <a href={`https://instagram.com/${application.state.instagram}`} target="blank" rel="noreferrer">
                                        <img src='/instagram.png' alt={""} />
                                    </a>
                                    : null
                            }
                            {
                                string.isNotEmpty(application.state.facebook)
                                    ?
                                    <a href={`https://web.facebook.com/${application.state.facebook}`} target="blank" rel="noreferrer">
                                        <img src='/fb.png' alt={""} />
                                    </a>
                                    : null
                            }
                            {
                                string.isNotEmpty(application.state.email)
                                    ?
                                    <a href={`mailto:${application.state.email}`}>
                                        <img src='/mail.png' alt={""} />
                                    </a>
                                    : null
                            }
                        </div>
                    </div>
                    : null
            }
            {
                application.state.vcard && string.isNotEmpty(application.state.firstName)
                    ?
                    <div className="vcard">
                        <a href={object.isValid(application.state.vcard) ? URL.createObjectURL(application.state.vcard) : `${socketURL}/uploads/${application.state.folderName}/${application.state.vcard}`} style={{ backgroundColor: application.state.color }} rel="noreferrer" download={`${application.state.firstName}.vcf`}>
                            save contact
                        </a>
                    </div>
                    : null
            }
            {
                !array.isEmpty(application.state.abouts)
                    ?
                    <>
                        {
                            application.state.abouts.map((about, index) => (
                                <div className="about" key={index}>
                                    <div className="title" style={{ backgroundColor: application.state.color }}>
                                        {about.name}
                                    </div>
                                    <p className="description">
                                        {about.description}
                                    </p>
                                </div>
                            ))
                        }
                    </>
                    : null
            }
            {
                !array.isEmpty(application.state.services)
                    ?
                    <div className="services">
                        <div className="title">
                            services
                        </div>
                        {
                            application.state.services.map((service, index) => (
                                string.isNotEmpty(service.name) && service.image
                                    ?
                                    <a href={`https://api.whatsapp.com/send/?phone=+${application.state.whatsapp}&text=I need "${string.toTitleCase(service.name)}" service&type=phone_number&app_absent=0`} key={index} className="service" style={{ backgroundColor: application.state.color }} target="blank" rel="noreferrer">
                                        <div className="image-container">
                                            <img src={object.isValid(service.image) ? URL.createObjectURL(service.image) : string.isNotEmpty(service.image) ? `${socketURL}/uploads/${application.state.folderName}/${service.image}` : ""} alt={service.name} />
                                        </div>
                                        <div className="name">
                                            {service.name}
                                        </div>
                                    </a>
                                    : null
                            ))
                        }
                    </div>
                    : null
            }

            {
                !array.isEmpty(application.state.products)
                    ?
                    <div className="products">
                        <div className="title">
                            products
                        </div>
                        <div className="row" >
                            {
                                application.state.products.map((product, index) => (
                                    string.isNotEmpty(product.name) && product.image
                                        ?
                                        <a href={`https://api.whatsapp.com/send/?phone=+${application.state.whatsapp}&text=I need "${string.toTitleCase(product.name)}" product&type=phone_number&app_absent=0`} className="col s4" key={index} target="blank" rel="noreferrer">
                                            <div className="product" style={{ backgroundColor: application.state.color }}>
                                                <img src={product.image && object.isValid(product.image) ? URL.createObjectURL(product.image) : string.isNotEmpty(product.image) ? `${socketURL}/uploads/${application.state.folderName}/${product.image}` : ""} alt={product.name} />
                                                <div className="name">
                                                    {product.name}
                                                </div>
                                            </div>
                                        </a>
                                        : null
                                ))
                            }
                        </div>
                    </div>
                    : null
            }
            {
                (string.isNotEmpty(application.state.location) || string.isNotEmpty(application.state.region) || string.isNotEmpty(application.state.country)) && string.isNotEmpty(application.state.color)
                    ?
                    <div className="footer" style={{ backgroundColor: application.state.color }}>
                        <div className="address">
                            {
                                string.isNotEmpty(application.state.location)
                                    ?
                                    <div className="location">
                                        {application.state.location}
                                    </div>
                                    : null
                            }
                            <div className="region-country">
                                {string.capitalize(application.state.region.trim())}, {string.capitalize(application.state.country.trim())}
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                string.isNotEmpty(application.state.qr) &&
                <QRCodeComponent qrValue={application.state.qr} />
            }
        </div>
    )
})

export default CardPreview